.card {
    padding: var(--space-16);
    border-radius: var(--space-8);
    background-color: var(--color-primary-main);
    color: var(--color-primary-contrast-neutral);
}

.content {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--space-16);
    gap: var(--space-24);
}

.textWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
}

.illustration {
    flex-shrink: 0;
}

.ctaWrapper {
    display: flex;
    flex-direction: column;
}

@media (min-width: 576px) {
    .ctaWrapper {
        flex-direction: row;
    }
}
