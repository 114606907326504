.image {
    display: block;
    position: relative;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    background-color: var(--color-deprecated-img-placeholder);
}

.localHeroMark {
    position: absolute;
    top: -4px;
    right: -4px;
    padding: 0;
}
