.button {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-border-light);
    border-radius: 50%;
    background-color: var(--color-neutral-0);
    box-shadow:
        4px 12px 20px 0 rgb(52 58 64 / 4%),
        4px 4px 14px 0 rgb(52 58 64 / 4%),
        2px 4px 8px 0 rgb(52 58 64 / 4%);
    color: var(--color-text-main);
    text-align: center;
    cursor: pointer;
}

.button:not(.isDisabled):hover {
    border: 1px solid var(--color-border-main);
    background-color: var(--color-neutral-100);
    color: var(--color-neutral-800);
}

.button:not(.isDisabled):focus {
    border: 1px solid var(--color-border-main);
    background-color: var(--color-neutral-0);
    color: var(--color-neutral-800);
}

.isDisabled {
    border: 1px solid var(--color-border-disabled);
    background-color: var(--color-background-disabled);
    color: var(--color-text-disabled);
    cursor: default;
}

.sizeS {
    width: 40px;
    height: 40px;
}

.sizeM {
    width: 48px;
    height: 48px;
}

.sizeS .icon {
    width: 20px;
    height: 20px;
}

.sizeM .icon {
    width: 24px;
    height: 24px;
}
