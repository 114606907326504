.wrapper {
    overflow: hidden;
}

.footerCopyright {
    margin-bottom: var(--space-24);
    color: var(--color-neutral-100);
    text-align: right;
}

.logo {
    display: block;
    color: var(--color-beige-100);
}
