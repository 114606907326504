.card {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: left;
    cursor: pointer;
}

.description {
    margin-top: var(--space-8);
}

.imageWrapper {
    position: relative;
    flex-shrink: 0;
    margin-bottom: var(--space-16);
}

.imageRatio {
    position: relative;
    overflow: hidden;
    border-radius: var(--space-16);
}

.imageRatio::after {
    position: absolute;
    inset: 0;
    background-color: var(--color-deprecated-img-hover);
    content: '';
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.card:hover .imageRatio::after {
    opacity: 1;
}

.destinationName {
    margin-bottom: var(--space-4);
}

.agencyName {
    display: block;
}

.agencyName::after {
    position: absolute;
    inset: 0;
    content: '';
}

.reviewsRating {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

@media (max-width: 767.98px) {
    .cardDisplayHeader {
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: var(--space-80) auto;
    }

    .cardDisplayHeader .imageWrapper {
        grid-area: 1 / 1;
        width: var(--space-80);
        margin-bottom: 0;
    }

    .cardDisplayHeader .nameAndReviews {
        grid-area: 1 / 2;
        padding-left: var(--space-16);
    }

    .cardDisplayHeader .description {
        grid-area: 2 / 1 / auto / 3;
        padding-top: var(--space-8);
    }
}

.localHeroBadge {
    z-index: var(--z-index-base);
    position: absolute;
    top: var(--space-16);
    left: var(--space-16);
}
