.card {
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.title {
    color: var(--color-text-main);
    hyphens: auto;
    overflow-wrap: break-word;
    pointer-events: none;
}

.image {
    margin-bottom: var(--space-8);
    border-radius: var(--space-8);
}

.imageRatio::before {
    z-index: 1;
    position: absolute;
    inset: 0;
    border-radius: var(--space-8);
    background-color: var(--color-deprecated-img-hover);
    content: '';
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.card:hover .imageRatio::before {
    opacity: 1;
}

.imageRatio {
    margin-bottom: var(--space-8);
    object-fit: cover;
}

.link {
    display: inline-block;
    padding: 0 var(--space-8);
}

.link::before {
    z-index: 1;
    position: absolute;
    inset: 0;
    content: '';
}

.reviewsRating {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 0 var(--space-8);
}
