.dropdownContent {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding-top: var(--space-4);
}

.dropdownContentContainer {
    border: 1px solid var(--color-neutral-0-10);
    border-radius: 12px;
    background-color: var(--color-green-700);
    box-shadow:
        -5px -8px 28px 0 rgb(0 53 38 / 50%),
        0 16px 45px 0 rgb(0 0 0 / 24%);
    color: var(--color-neutral-100);
}

.darkBackground {
    background-color: var(--color-green-800);
}

@media (min-width: 1400px) {
    .dropdownContent {
        right: var(--space-40);
        left: var(--space-40);
    }
}
