.nav,
.navNoMenu {
    display: flex;
    z-index: var(--z-index-appbar);
    position: sticky;
    top: 0;
    width: 100%;
    height: var(--primary-nav-height);
    padding: 0 var(--space-8);
    border-bottom: 1px solid var(--color-neutral-0-10);
    background-color: var(--color-green-800);
    color: var(--color-neutral-100);
}

.navNoMenu {
    align-items: center;
    justify-content: center;
}

.leftBlock,
.logoBlock,
.rightBlock {
    display: flex;
    align-items: center;
    width: 100%;
}

.leftBlock {
    justify-content: flex-start;
    order: 1;
    gap: var(--space-8);
}

.logoBlock {
    flex-grow: 1;
    justify-content: center;
    order: 2;
}

.rightBlock {
    justify-content: flex-end;
    order: 3;
    gap: var(--space-8);
}

.logoLink {
    display: flex;
    position: relative;
    flex-shrink: 0;
    align-items: center;
    height: 100%;
}

.logo {
    height: 13px;
}

.dropdownContainer {
    z-index: var(--z-index-appbar);
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}

.previewModeText {
    position: absolute;
    right: 0;
    bottom: calc(var(--space-24) * -1);
    color: var(--color-blue-800);
    text-shadow:
        2px 0 var(--color-neutral-0),
        -2px 0 var(--color-neutral-0),
        0 2px var(--color-neutral-0),
        0 -2px var(--color-neutral-0),
        1px 1px var(--color-neutral-0),
        -1px -1px var(--color-neutral-0),
        1px -1px var(--color-neutral-0),
        -1px 1px var(--color-neutral-0);
}

@media (min-width: 576px) {
    .nav,
    .navNoMenu {
        padding: 0 var(--space-16);
    }
}

@media (min-width: 992px) {
    .nav,
    .navNoMenu {
        padding: 0 var(--space-24);
    }
}

@media (max-width: 1199.98px) {
    .hiddenMobile {
        display: none;
    }
}

@media (min-width: 1200px) {
    .nav,
    .navNoMenu {
        padding: 0 var(--space-32);
    }

    .navNoMenu {
        justify-content: flex-start;
    }

    .logoBlock {
        flex-grow: 0;
        justify-content: flex-start;
        order: 1;
        width: auto;
        padding-right: var(--space-32);
    }

    .leftBlock {
        flex-grow: 1;
        order: 2;
    }

    .rightBlock {
        order: 3;
    }
}
