.tabContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--space-16);
    gap: var(--space-24);
}

.title {
    color: var(--color-yellow-300);
}

.highlightsSection {
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
}

.menuImagesWrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: var(--space-8);
}

.columnsSection {
    display: flex;
    flex-flow: row nowrap;
    gap: var(--space-32);
}

.column {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
    width: 100%;
    gap: var(--space-8);
}

.columnItemsWrapper {
    display: flex;
    flex-flow: column wrap;
}

.columnItem {
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: var(--space-8) 0;
    gap: var(--space-8);
}
