.footer {
    --footer-background-color: var(--color-green-800);

    padding: var(--space-32) 0 0 0;
    background-color: var(--footer-background-color);
    color: var(--color-neutral-0);
}

.brand {
    display: flex;
    justify-content: center;
    padding: var(--space-40) var(--space-24);
    background-color: var(--color-beige-100);
}

.newsletter {
    display: flex;
    justify-content: center;
    padding: var(--space-40) var(--space-16);
    background-color: var(--color-green-700);
}

.newsletterWrapper {
    max-width: 848px;
}

.footerLogoGrid,
.footerLinks {
    margin-bottom: var(--space-24);
}
