.wrapper {
    --container-mx: calc(0.5 * var(--space-24));
    --container-my: calc(0.5 * var(--space-32));
    --item-mx: calc(0.5 * var(--space-32));
    --item-my: calc(0.5 * var(--space-32));

    display: flex;
    flex-wrap: wrap;
    place-content: flex-start center;
    align-items: flex-start;
    align-self: stretch;
    margin: calc(-1 * var(--container-mx)) calc(-1 * var(--container-my));
}

.container {
    display: flex;
    flex: 1 0 0;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    min-width: 300px;
    margin: calc(var(--container-mx) - var(--item-mx)) calc(var(--container-my) - var(--item-my));
}

.item {
    flex: 1 0 0;
    min-width: 200px;
    margin: var(--item-mx) var(--item-my);
    padding: 0 var(--space-8);
}
