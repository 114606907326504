.navMenuLink {
    display: flex;
    align-items: center;
    padding: var(--space-8) 0;
    color: var(--color-neutral-100);
    cursor: pointer;
}

.navMenuLink:hover {
    text-decoration: underline;
}
