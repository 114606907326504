.container {
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1200px) {
    .container {
        margin: var(--space-16) var(--space-24) var(--space-24) var(--space-24);
    }
}
