.cover {
    position: relative;
    width: 100%;
    height: var(--cover-height);
    color: var(--color-deprecated-white);
}

.layout {
    position: relative;
    height: 100%;
}

.row {
    position: absolute;
    bottom: var(--space-48);
}

.title {
    margin-bottom: var(--space-24);
}

@media (min-width: 768px) {
    .row {
        bottom: var(--space-80);
    }
}
