.badge {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: 0;
    border-radius: 50%;
    color: var(--color-neutral-0);
    text-transform: uppercase;
    user-select: none;
}

.iconSvg {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
}
