.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--space-56);
    padding: var(--space-16) var(--space-24);
    background-color: var(--color-primary-main);
    color: var(--color-primary-contrast-neutral);
    text-align: center;
}

.title {
    margin-bottom: var(--space-8);
}

.description {
    margin-bottom: var(--space-16);
}

@media (min-width: 768px) {
    .container {
        padding: var(--space-40) var(--space-56);
    }
}
