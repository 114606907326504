.item {
    margin-bottom: var(--space-16);
}

.rememberWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--space-32);
}

.submit {
    margin-bottom: var(--space-48);
}
