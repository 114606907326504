.header {
    --vertical-padding: var(--space-16);

    display: grid;
    position: relative;
    grid-template-columns: min-content auto min-content;
    align-items: center;
    padding: 0 var(--modal-content-horizontal-padding);
    overflow: visible;
    border-bottom: 1px solid var(--color-border-main);
    background-color: var(--color-background-main);
}

.header.dark {
    border-bottom: none;
    background-color: var(--color-text-main);
    color: var(--color-background-main);
}

.title {
    box-sizing: content-box;
    grid-column: 1 / 3;
    place-self: center center;
    min-height: var(--line-height-s);
    padding: var(--vertical-padding) var(--space-16) var(--vertical-padding) 0;
    text-align: left;
}

.rightButton {
    grid-column: 3;
    place-self: center end;
}

.crossButton {
    color: currentColor;
}

@media (min-width: 768px) {
    .header {
        --vertical-padding: var(--space-24);
    }

    .title {
        grid-column: 1 / 3;
        place-self: center start;
        padding-left: 0;
    }
}
