.card {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: var(--space-32) var(--space-12);
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
}

.blackOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 30%);
}

.label {
    position: relative;
    color: var(--color-primary-contrast-neutral);
    hyphens: auto;
    text-align: center;
    text-shadow:
        0 4px 4px rgb(31 31 31 / 20%),
        -2px -2px 4px rgb(31 31 31 / 20%),
        0 0 4px rgb(31 31 31 / 20%);
    overflow-wrap: break-word;
}

.image {
    object-fit: cover;
    object-position: center;
    transform: scale(1.05);
    transition: transform 200ms ease-out 100ms;
    will-change: transform;
}

.blurredImage .image {
    filter: blur(8px);
}

.card:hover .image {
    transform: scale(1.15);
}

.grainOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: normal;
    opacity: 0.1;
    pointer-events: none;
}
