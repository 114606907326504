.navItem {
    --nav-item-height: var(--space-40);

    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: var(--nav-item-height);
    height: var(--nav-item-height);
    padding: 0;
    border: 0;
    border-radius: 50%;
    background-color: transparent;
    color: var(--color-neutral-100);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xs);
    cursor: pointer;
}

.navItem:hover {
    background-color: var(--color-neutral-0-10);
}

.icon {
    width: 24px;
    height: 24px;
}
