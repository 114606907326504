.content {
    padding: var(--space-4) var(--space-16);
    border: 1px solid var(--color-deprecated-ink);
    border-radius: 8px;
    background-color: var(--color-deprecated-ink);
    color: var(--color-deprecated-white);
    font-size: var(--font-size-xs);
    line-height: var(--line-height-xs);
}

.arrow {
    fill: var(--color-deprecated-ink);
}
