.menuImageContainer {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    padding: 0 var(--space-16);
    gap: var(--space-8);
}

.menuImageFirstHighlightedWrapper {
    width: 100%;
}

.menuImageFirstHighlightedWrapper,
.menuImageOtherHighlightedWrapper {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    gap: var(--space-8);
}

.thematicLinkContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: var(--space-16);
    gap: var(--space-16) 0;
}

.blockDivider {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.divider {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
}

.footer {
    padding: var(--space-56) var(--space-16) 0 var(--space-16);
    text-align: center;
}
