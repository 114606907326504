.navMenuArrowButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: var(--space-12) var(--space-16);
    gap: var(--space-16);
    border-radius: 8px;
    color: var(--color-primary-contrast-neutral);
    transition: background-color 300ms cubic-bezier(0.8, 0, 0.2, 1);
}

.navMenuArrowButton:hover {
    background-color: var(--color-green-800-30);
}

.iconCircle {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    transform: translateX(-100%);
    border-radius: 50%;
    background-color: var(--color-green-900-30);
    opacity: 0;
    transition: all 300ms cubic-bezier(0.8, 0, 0.2, 1);
}

.icon {
    width: 16px;
    height: 16px;
}

.navMenuArrowButton:hover .iconCircle {
    transform: translateX(0);
    opacity: 1;
}
