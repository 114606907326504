.copyright {
    z-index: 30;
    position: absolute;
    right: var(--space-4);
    bottom: var(--space-4);
}

.symbolBackground {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--font-size-m);
    height: var(--font-size-m);
    border-radius: 50%;
    background-color: var(--color-deprecated-white-alpha-30);
    cursor: pointer;
}

.symbol {
    width: var(--font-size-body);
    color: var(--color-deprecated-white);
}

.author {
    display: none;
    z-index: 1;
    position: absolute;
    right: -2px;
    bottom: -2px;
    padding: var(--space-8) var(--space-16);
    border-radius: 3px;
    background-color: var(--color-deprecated-white);
    box-shadow: 6px 6px 7px 0 var(--color-deprecated-black-alpha-30);
    color: var(--color-deprecated-ink);
    white-space: nowrap;
    cursor: default;
}

.copyright:hover .author {
    display: inherit;
}
