.container {
    display: flex;
    flex-direction: row;
}

.main {
    width: 100%;
    padding: var(--space-16);
}

.side {
    min-width: 336px;
    min-height: 250px;
    padding: var(--space-4);
}

.separator {
    margin: var(--space-16) 0;
    border-top: 1px solid var(--color-neutral-0-10);
}

.blockTitle {
    padding-left: var(--space-16);
    color: var(--color-yellow-300);
}

.blockDestination {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: var(--space-4);
}

.blockDestinationLinksList {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    width: 100%;
}

.hasItineraries .blockDestinationLinksListItem {
    width: 50%;
}

.blockRegions {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-16);
}

.destinationButton {
    padding-left: var(--space-16);
}

.navButtonRegionLinkContainer {
    display: flex;
    flex-flow: row wrap;
    gap: var(--space-8);
}

.blockDivider {
    display: flex;
    align-items: center;
    gap: var(--space-8);
}

.destinationHighlightCard {
    display: flex;
    position: relative;
    height: 100%;
    padding: var(--space-8);
    overflow: hidden;
    border-radius: 8px;
    background-color: var(--color-deprecated-img-placeholder);
    color: var(--color-primary-main);
}

.destinationHighlightCardImage {
    object-fit: cover;
    object-position: center;
    transition: transform 200ms ease-out 100ms;
}

.destinationHighlightCard:hover .destinationHighlightCardImage {
    transform: scale(1.1);
}

.destinationHighlightCardText {
    display: inline-flex;
    z-index: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
    padding: var(--space-8) var(--space-16);
    border-radius: 8px;
    background-color: var(--color-beige-200);
}

.destinationHighlightCardText::after {
    position: absolute;
    inset: 0;
    content: '';
}

.arrowCircle {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-top: auto;
    transform: translateX(0);
    border-radius: 50%;
    background-color: var(--color-yellow-500);
    color: var(--color-text-main);
    transition: transform 300ms cubic-bezier(0.8, 0, 0.2, 1);
}

.destinationHighlightCard:hover .arrowCircle {
    transform: translateX(4px);
}

.arrowIcon {
    width: 16px;
    height: 16px;
}
