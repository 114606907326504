.localHeroBadge {
    display: inline-flex;
    z-index: var(--z-index-base);
    align-items: center;
    align-self: flex-start;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    padding: var(--space-4) 10px;
    border-radius: var(--space-24);
    background-color: var(--color-secondary-main);
    color: var(--color-secondary-contrast-neutral);
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-xxs);
    text-transform: uppercase;
    user-select: none;
}

.localHeroIcon {
    margin-right: var(--space-4);
}
