.card {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 1px solid var(--color-neutral-200);
    border-radius: 8px;
    cursor: pointer;
}

.coverWrapper::before {
    display: flex;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: var(--color-deprecated-img-hover);
    content: '';
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.card:hover .coverWrapper::before {
    opacity: 1;
}

.coverImage {
    object-fit: cover;
    object-position: center;
}

.destinationName {
    margin-bottom: var(--space-4);
}

.body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: var(--space-16) var(--space-16) var(--space-24) var(--space-16);
}

.bodyChild {
    margin-bottom: var(--space-16);
}

.bodyChild:last-child {
    margin-bottom: 0;
}

.wishButtonContainer {
    z-index: 2;
    position: absolute;
    top: var(--space-16);
    right: var(--space-16);
}

.title {
    --line-clamp: 2;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--line-clamp);
    line-clamp: var(--line-clamp);
    max-width: 100%;
    min-height: calc(var(--line-height-s) * var(--line-clamp));
    max-height: calc(var(--line-height-s) * var(--line-clamp));
    margin-bottom: var(--space-8);
    overflow: hidden;
    color: var(--color-neutral-900);
    line-height: var(--line-height-s);
    text-overflow: ellipsis;
}

.titleLink::before {
    z-index: 1;
    position: absolute;
    inset: 0;
    content: '';
}

.badge {
    z-index: 1;
    margin-right: 8px;
}

.detailsContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pointsOfInterest {
    min-height: calc(var(--line-height-xs) * var(--line-clamp));
    color: var(--color-deprecated-ink-light);
}

.agency {
    display: flex;
    align-items: center;
}

.agencyPictureWrapper {
    position: relative;
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    margin-right: var(--space-8);
}
