.menu {
    z-index: var(--z-index-appbar);
    width: 100%;
    min-width: 240px;
    padding: var(--space-8) 0 var(--space-4) 0;
    border-radius: 4px;
    background-color: var(--color-green-700);
    color: var(--color-neutral-0);
}

.menuListItem {
    margin: 0 var(--space-16);
}

.menuListItem + .menuListItem {
    margin-top: var(--space-8);
}

.hr {
    margin: var(--space-8) 0;
}

.profileLetter {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    background-color: var(--color-custom-brand-red);
    color: var(--color-neutral-0);
    text-transform: uppercase;
    user-select: none;
}

@media (min-width: 1200px) {
    .menu {
        width: auto;
    }
}
