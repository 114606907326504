.wishListed .icon {
    animation-duration: 0.5s;
    animation-name: bounce-heart-wishlist;
    transition: transform 0.5s;
}

.iconBorderPath {
    fill: currentColor;
}

.iconInnerAreaPath {
    fill: transparent;
}

.wishListed .iconBorderPath {
    fill: transparent;
}

.wishListed .iconInnerAreaPath {
    fill: var(--color-custom-brand-red);
}

@keyframes bounce-heart-wishlist {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}
