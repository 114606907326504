.card {
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid var(--color-border-main);
    border-radius: 8px;
}

.imageWrapper {
    position: relative;
    width: 100%;
    min-height: 200px;
}

.imageWrapper img {
    object-fit: cover;
    border-radius: 8px 8px 0 0;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.card:hover .imageWrapper img {
    transform: scale(1.02);
    filter: brightness(0.8);
}

.content {
    display: flex;
    flex-direction: column;
    padding: var(--space-24);
    gap: var(--space-24);
}

.titleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-16);
}

.link::before {
    z-index: 1;
    position: absolute;
    inset: 0;
    content: '';
}

.stateWrapper {
    display: flex;
    align-items: center;
}

.stateIndicator {
    width: 10px;
    height: 10px;
    margin-right: var(--space-8);
    border-radius: 50%;
}

.stateIndicatorBlue {
    background-color: var(--color-info-main);
}

.stateIndicatorGreen {
    background-color: var(--color-success-main);
}

.stateIndicatorGrey {
    background-color: var(--color-neutral-700);
}

.informationWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
}

.informationBlock {
    display: flex;
    flex: 0 1 auto;
    justify-content: space-between;
    gap: var(--space-4);
}

.agency {
    display: flex;
    align-items: center;
}

.agencyPictureWrapper {
    position: relative;
    flex-shrink: 0;
    width: var(--space-48);
    height: var(--space-48);
    margin-right: var(--space-16);
}

.agencyName {
    display: flex;
    flex-direction: column;
}

@media (min-width: 576px) {
    .informationWrapper {
        flex-direction: row;
        gap: var(--space-48);
    }

    .informationBlock {
        flex-direction: column;
        justify-content: start;
    }
}

@media (min-width: 768px) {
    .card {
        flex-direction: row;
    }

    .imageWrapper {
        width: 40%;
    }

    .imageWrapper img {
        border-radius: 8px 0 0 8px;
    }

    .content {
        width: 60%;
    }

    .titleWrapper {
        flex-direction: row;
        align-items: center;
        gap: var(--space-16);
    }
}
