.linkTitle {
    display: block;
    width: 100%;
    margin-bottom: var(--space-16);
    padding-bottom: var(--space-16);
    border-bottom: solid 1px var(--color-neutral-0);
}

.link {
    display: block;
    padding: var(--space-4) 0;
    color: var(--color-neutral-0);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    font-family: var(--font-family-primary);
    text-align: left;
    cursor: pointer;
}

.link,
.link:hover,
.link:focus {
    border: 0;
    background: none;
}
