.wrapper {
    display: flex;
    position: relative;
    flex-shrink: 0;
    flex-flow: column nowrap;
    max-height: 600px;
    padding: var(--space-16);
    overflow: hidden;
    gap: var(--space-8);
    transform-origin: 0 0;
    border-radius: 8px;
    background-color: var(--color-green-100);
    animation: fade-in 0.9s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.buttonContainer {
    display: flex;
    flex: 1 1 0;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: stretch;
}

.hide {
    max-height: 0;
    padding: 0;
    opacity: 0;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (min-width: 992px) {
    .wrapper {
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: center;
    }
}
