.card {
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
}

.title {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    padding: 0 var(--space-16) var(--space-16) var(--space-16);
    color: var(--color-deprecated-white);
    hyphens: auto;
    overflow-wrap: break-word;
    pointer-events: none;
}

.card.small .title {
    padding: 0 var(--space-8) var(--space-8) var(--space-8);
}

.link {
    z-index: 1;
    position: absolute;
    inset: 0;
}
