.carousel {
    position: relative;
}

.overflowContainer {
    overflow: hidden;
}

.translateContainer {
    flex-wrap: nowrap;
    transform: translateX(0);
    transition: transform 0.5s ease-out;
}

.slide {
    position: relative;
}

.carouselFullHeight,
.carouselFullHeight .overflowContainer,
.carouselFullHeight .slide,
.carouselFullHeight .translateContainer {
    height: 100%;
}

.navButton {
    z-index: 10;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.navLeftButtonInside {
    left: var(--space-8);
}

.navRightButtonInside {
    right: var(--space-8);
}

.navLeftButtonOverlap {
    left: 0;
    transform: translate(-50%, -50%);
}

.navRightButtonOverlap {
    right: 0;
    transform: translate(50%, -50%);
}

.dots {
    display: flex;
    position: absolute;
    bottom: var(--space-16);
    left: 50%;
    flex-flow: row nowrap;
    gap: var(--space-8);
    transform: translateX(-50%);
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--color-green-200);
}

.selectedDot {
    background-color: var(--color-neutral-0);
}

/* stylelint-disable-next-line media-feature-name-value-allowed-list */
@media (max-width: 374.98px) {
    .slideLarge {
        width: calc(260px + var(--grid-gutter-x));
    }
}

/* stylelint-disable-next-line media-feature-name-value-allowed-list */
@media (min-width: 375px) and (max-width: 575.98px) {
    .slideLarge {
        width: calc(300px + var(--grid-gutter-x));
    }
}

@media (max-width: 575.98px) {
    .carousel {
        margin-right: calc(var(--grid-gutter-x) * -1);
        margin-left: calc(var(--grid-gutter-x) * -1);
    }

    .slide {
        scroll-snap-align: center;
    }

    .slideMedium {
        width: calc(220px + var(--grid-gutter-x));
    }

    .slideFull {
        width: 100%;
    }

    .translateContainer::-webkit-scrollbar {
        display: none;
    }

    .translateContainer {
        padding-right: var(--grid-gutter-x);
        padding-left: var(--grid-gutter-x);
        overflow: auto hidden;
        scroll-snap-type: x mandatory;
        -ms-overflow-style: none;
        transform: translateX(0) !important;
        scrollbar-width: none;
    }
}
