.link {
    display: flex;
    align-items: center;
    padding: 0 var(--space-8);
    overflow: hidden;
    outline: none;
    color: var(--color-neutral-100);
    cursor: pointer;
}

.small {
    height: var(--space-32);
}

.medium {
    height: var(--space-40);
}

.inline {
    display: inline-flex;
}

.link:hover {
    text-decoration: underline;
}

.link:active,
.link:focus {
    background: var(--color-neutral-0-10);
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: var(--space-8);
}
