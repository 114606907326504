.itineraryListCta {
    margin-bottom: var(--space-32);
    padding: 0 var(--space-16);
}

.mobileMenuList {
    display: flex;
    flex-direction: column;
    padding: 0 var(--space-16);
    gap: var(--space-8);
}
