.menu {
    z-index: var(--z-index-appbar);
    width: auto;
    min-width: 240px;
    padding: var(--space-8) 0 var(--space-4) 0;
    border-bottom: 4px solid var(--color-green-900);
    background-color: var(--color-green-800);
    color: var(--color-neutral-0);
}

.menuListItem {
    margin: 0 var(--space-16);
}

.menuListItem + .menuListItem {
    margin-top: var(--space-8);
}

@media (max-width: 1199.98px) {
    .menu {
        display: none;
    }
}
