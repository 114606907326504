.list {
    --line-clamp: 2;

    display: block;
    display: -webkit-box;
    -webkit-line-clamp: var(--line-clamp);
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item {
    display: inline;
}

.item + .item::before {
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-right: 8px;
    margin-left: 8px;
    border-radius: 50%;
    background-color: currentColor;
    content: '';
    vertical-align: middle;
}
