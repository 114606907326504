.primary-xl-extra-bold {
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);
    font-family: var(--font-family-primary);
}

.primary-l-extra-bold {
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-l);
    line-height: var(--line-height-l);
    font-family: var(--font-family-primary);
}

.primary-m-extra-bold {
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-m);
    line-height: var(--line-height-m);
    font-family: var(--font-family-primary);
}

.primary-s-extra-bold {
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-s);
    line-height: var(--line-height-s);
    font-family: var(--font-family-primary);
}

.primary-body-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    font-family: var(--font-family-primary);
}

.primary-body-regular {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    font-family: var(--font-family-primary);
}

.primary-xs-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xs);
    line-height: var(--line-height-xs);
    font-family: var(--font-family-primary);
}

.primary-xs-regular {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-xs);
    line-height: var(--line-height-xs);
    font-family: var(--font-family-primary);
}

.primary-xxs-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xxs);
    line-height: var(--line-height-xxs);
    font-family: var(--font-family-primary);
}

.primary-xxs-regular {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-xxs);
    line-height: var(--line-height-xxs);
    font-family: var(--font-family-primary);
}

.secondary-xxl-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-secondary-xxl);
    line-height: var(--line-height-secondary-xxl);
    font-family: var(--font-family-secondary);
}

.secondary-xl-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-secondary-xl);
    line-height: var(--line-height-secondary-xl);
    font-family: var(--font-family-secondary);
}

.secondary-l-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-secondary-l);
    line-height: var(--line-height-secondary-l);
    font-family: var(--font-family-secondary);
}

.secondary-m-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-secondary-m);
    line-height: var(--line-height-secondary-m);
    font-family: var(--font-family-secondary);
}

.secondary-s-bold {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-secondary-s);
    line-height: var(--line-height-secondary-s);
    font-family: var(--font-family-secondary);
}

.secondary-s-regular {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-secondary-s);
    line-height: var(--line-height-secondary-s);
    font-family: var(--font-family-secondary);
}

.secondary-xs-regular {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-secondary-xs);
    line-height: var(--line-height-secondary-xs);
    font-family: var(--font-family-secondary);
}

.secondary-xxs-regular {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-secondary-xxs);
    line-height: var(--line-height-secondary-xxs);
    font-family: var(--font-family-secondary);
}
