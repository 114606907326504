.wrapper {
    display: flex;
    flex-direction: column;

    --padding-input-x: var(--space-16);
    --padding-input-icon: var(--space-4);
    --input-bg-color: var(--color-deprecated-white);
}

label.centered {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.inputContainer {
    display: flex;
    flex-flow: row nowrap;
    gap: var(--space-8);
}

.incrementalButton {
    width: 46px;
    height: 46px;
}

.inputWrapper {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: space-between;
    min-width: 75px;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    padding: 1.1rem var(--padding-input-x);
    border-radius: var(--space-8);
    background-color: var(--input-bg-color);
    box-shadow: 0 0 0 1px var(--color-deprecated-ink-lighter) inset;
}

.input {
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    background: none;
}

.inputWrapperWithIncrementalButtons .input {
    width: 64px;
    text-align: center;
}

.inputUnit {
    flex-shrink: 0;
    margin-left: var(--space-8);
    color: var(--color-deprecated-ink-lighter);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-xs);
}

.inputIcon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-left: var(--space-8);
}

.inputIconAsButton {
    all: unset;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-left: var(--space-8);
}

.head {
    margin-bottom: var(--space-8);
}

.textError {
    margin-top: var(--space-4);
    color: var(--color-deprecated-red);
}

.input::placeholder {
    color: var(--color-deprecated-ink-lighter);
}

.inputWrapper:hover {
    box-shadow: 0 0 0 2px var(--color-deprecated-ink-lighter) inset;
}

.inputWrapper:focus-within {
    box-shadow: 0 0 0 2px var(--color-deprecated-ink-light) inset;
}

.inputError {
    box-shadow: 0 0 0 1px var(--color-deprecated-red) inset;
}

.inputError:hover,
.inputError:focus-within {
    box-shadow: 0 0 0 2px var(--color-deprecated-red) inset;
}

.inputDisabled,
.inputDisabled:hover,
.inputDisabled:focus-within {
    background-color: var(--color-deprecated-ink-extra-light);
    box-shadow: 0 0 0 1px var(--color-deprecated-ink-lighter) inset;
}

/* To hide input number arrows. */

/* Chrome, Safari, Edge, Opera */
.input[type='number']::-webkit-outer-spin-button,
.input[type='number']::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

/* Firefox */
.input[type='number'] {
    appearance: textfield;
}

.labelDetail {
    margin-left: var(--space-8);
    color: var(--color-deprecated-ink-lighter);
}
