.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: var(--space-16);
}

.messagesContainer {
    display: flex;
    flex-direction: column;
    padding: var(--space-16);
    overflow-y: auto;
    gap: var(--space-16);
}

.inputContainer {
    display: flex;
    flex-flow: row nowrap;
    padding: var(--space-16);
    gap: var(--space-8);
    border-top: 1px solid var(--color-gray-200);
}

.input {
    width: 100%;
}
