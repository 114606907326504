.tabContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--space-8);
}

.menuImageContainer {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    gap: var(--space-8);
}

.menuImageFirstHighlightedWrapper,
.menuImageOtherHighlightedWrapper {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    gap: var(--space-8);
}

.thematicLinkContainer {
    display: flex;
    flex-flow: row wrap;
    padding: var(--space-8);
    gap: var(--space-16);
}

.blockDivider {
    display: flex;
    align-items: center;
    gap: var(--space-16);
}
