.container {
    display: flex;
    flex-direction: row;
    min-height: 344px;
    padding: var(--space-4);
    gap: var(--space-8);
}

.tabsContainer {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
    width: 328px;
    gap: var(--space-8);
}

.tabsContentsContainer {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    padding: var(--space-8);
    gap: var(--space-8);
    border-radius: 8px;
    background-color: var(--color-green-700);
}

.tabContent {
    display: flex;
    width: 100%;
}

.tabContent[data-state='inactive'] {
    display: none;
}
