.paymentLogosWrapper {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: var(--space-4);
}

.paymentLogo {
    width: 60px;
}
