.primaryNav {
    --primary-nav-height: var(--space-56);

    z-index: var(--z-index-appbar);
    position: sticky;
    top: 0;
    width: 100%;
    transform: translateY(0);
    background-color: var(--color-green-800);
    transition: transform 0.3s ease-in-out;
}

.primaryNav.hidden:not(.isOpen) {
    transform: translateY(-100%);
}

.primaryNav.previewMode {
    background-color: var(--color-blue-800);
}

@media (max-width: 1199.98px) {
    .isOpen {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        overflow-y: auto;
    }
}
