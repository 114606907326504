.form {
    margin: auto 0;
}

.title,
.subTitle,
.inscriptionDoneTitle,
.checkboxWrapper {
    margin-bottom: var(--space-24);
}

.input {
    flex-grow: 1;
    min-width: 290px;
    margin-right: var(--space-16);
}

.inputButtonWrapper {
    --input-button-wrapper-gap: 8px;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-top: calc(var(--input-button-wrapper-gap) * -1);
    margin-bottom: calc(var(--space-24) - var(--input-button-wrapper-gap));
}

.input,
.button {
    margin-top: var(--input-button-wrapper-gap);
    margin-bottom: var(--input-button-wrapper-gap);
}

.cgu,
.dataPolicy,
.dataPolicyButton {
    color: var(--color-neutral-700);
}

.dataPolicyContent {
    margin: var(--space-16) 0;
}

.themeGreen {
    color: var(--color-neutral-100);
}

.themeGreen .cgu,
.themeGreen .dataPolicy,
.themeGreen .dataPolicyButton {
    color: var(--color-neutral-300);
}
