.container {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--space-56);
}

.title {
    color: var(--color-text-main);
}

.subtitle {
    margin-top: var(--space-8);
    color: var(--color-text-light);
}

.titleContainer {
    margin-bottom: var(--space-24);
}
