.navBarItem {
    display: flex;
    align-items: center;
    padding: var(--space-8);
    border: 0;
    border-radius: 50%;
    background-color: transparent;
    color: var(--color-neutral-100);
    cursor: pointer;
}

.navBarItem:hover {
    background-color: var(--color-neutral-0-10);
}

.navBarItem.isMenuOpen {
    background-color: var(--color-neutral-0-10);
}

.iconLeading {
    flex-shrink: 0;
    justify-content: center;
    width: 20px;
    height: 20px;
}

.iconTrailing {
    display: none;
}

.text {
    display: none;
}

@media (min-width: 1200px) {
    .navBarItem {
        width: fit-content;
        padding: var(--space-4) var(--space-8);
        gap: var(--space-4);
        border-radius: 0;
        color: var(--color-neutral-0-70);
    }

    .navBarItem.isMenuOpen {
        background-color: transparent;
        color: var(--color-neutral-100);
    }

    .navBarItem:hover {
        background-color: transparent;
        color: var(--color-neutral-100);
    }

    .navBarItem:focus-visible {
        border-radius: 2px;
        outline: 1px solid var(--color-green-600);
        outline-offset: 1px;
    }

    .iconLeading {
        display: none;
    }

    .displayDesktopIconLeading .iconLeading {
        display: flex;
    }

    .iconTrailing {
        display: block;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
    }

    .text {
        display: block;
    }
}
