@keyframes wave {
    0%,
    60%,
    100% {
        transform: initial;
    }

    30% {
        transform: translateY(-10px);
    }
}

.loader {
    display: inline-block;
    position: relative;
    margin-left: var(--space-8);
    white-space: nowrap;
}

.dot {
    display: block;
    width: 6px;
    height: 6px;
    float: left;
    border-radius: 50%;
    background: currentColor;
    animation: wave 1.3s linear infinite;
}

.dot + .dot {
    margin-left: var(--space-8);
}

.dot:nth-child(2) {
    animation-delay: -1.1s;
}

.dot:nth-child(3) {
    animation-delay: -0.9s;
}
