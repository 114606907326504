.title {
    margin-bottom: var(--space-16);
    padding: 0 var(--space-16);
}

.carousel {
    position: relative;
}

.scroller {
    display: flex;
    flex-wrap: nowrap;
    gap: var(--space-16);
    -ms-overflow-style: none;
    padding-right: var(--space-16);
    padding-left: var(--space-16);
    overflow: auto hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;
}

.carousel::-webkit-scrollbar {
    display: none;
}

.slide {
    flex-shrink: 0;
    width: 120px;
}

.findDestination {
    display: block;
    position: relative;
    padding-top: 100%;
    overflow: hidden;
    border: 2px solid var(--color-neutral-0);
    border-radius: 8px;
    transition: background-color 0.3s ease-out;
}

.findDestination:hover {
    background-color: var(--color-neutral-0-20);
}

.findDestinationText {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 var(--space-8) var(--space-8) var(--space-8);
    hyphens: auto;
}
