.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--space-16);
    padding: 0 var(--grid-gutter-x);
}

.wrapper {
    display: flex;
    flex-flow: column wrap;
    gap: var(--space-56);
}

.title {
    color: var(--color-yellow-300);
}

.columnsSection {
    display: flex;
    flex-flow: row wrap;
    padding: 0 var(--space-16);
    gap: var(--space-32);
}

.column {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
    width: 100%;
    gap: var(--space-8);
}

.columnItemsWrapper {
    display: flex;
    flex-flow: column wrap;
}

.columnItem {
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: var(--space-8) 0;
    gap: var(--space-8);
}

.footer {
    padding: var(--space-56) var(--space-16) 0 var(--space-16);
    text-align: center;
}
