.mark {
    display: inline-flex;
    position: relative;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: var(--space-4);
    border-radius: 50%;
    background-color: var(--color-yellow-400);
    color: var(--color-neutral-900);
}
