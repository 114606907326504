.messageGroup {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--space-8);
    animation: fade-in 0.5s ease-in-out;
}

.messageWrapperRight {
    flex-direction: row-reverse;
}

.profilePicture {
    flex-shrink: 0;
    width: 32px;
}

.evaneosBadge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    padding: 6px;
    border-radius: 50%;
    background-color: var(--color-primary-main);
}

.evaneosIcon {
    color: var(--color-primary-contrast-neutral);
}

.messageBubble {
    padding: var(--space-16);
    border-radius: 8px;
    background-color: var(--color-background-variant);
}

/* stylelint-disable-next-line block-no-empty */
.loader {
}

.messageBubble:not(.loader) {
    flex: 0 1 80vw;
}

.messageWrapperRight .messageBubble {
    background-color: var(--color-green-100);
}

@media (min-width: 768px) {
    .messageBubble:not(.loader) {
        flex: 0 1 40vw;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
