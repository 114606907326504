.wrapper {
    --header-height: 216px;
}

.header {
    display: none;
}

.headbar {
    z-index: 1;
    position: sticky;
    top: 0;
}

.main {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - var(--primary-nav-height));
}

@media (min-width: 768px) {
    .header {
        display: block;
        height: var(--header-height);
    }

    .main {
        flex-direction: row;
        align-items: flex-start;
        min-height: calc(100vh - var(--primary-nav-height) - var(--header-height));
    }

    .headbar {
        position: static;
        flex: 1 0 0;
        min-width: 412px;
    }

    .discussion {
        flex: 3 0 0;
    }
}
