.navItem {
    --nav-item-height: var(--space-40);

    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    height: var(--nav-item-height);
    padding: 0 var(--space-16);
    border: 0;
    border-radius: 2px;
    background-color: transparent;
    color: var(--color-neutral-100);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xs);
    line-height: calc(var(--nav-item-height) / 2);
    text-align: left;
    cursor: pointer;
}

.navItem[aria-expanded='true'],
.navItem[data-active] {
    background-color: var(--color-neutral-0-10);
}

.navItem:hover {
    background-color: var(--color-neutral-0-10);
}

.isDesktop {
    width: auto;
    padding: 0 var(--space-12);
}

.isDesktop.hasIconLeading {
    padding-left: var(--space-8);
}

.isDesktop.hasIconTrailing {
    padding-right: var(--space-8);
}

.iconTrailing,
.iconLeading {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
}

.iconLeading {
    margin-right: var(--space-8);
}

.iconTrailing {
    margin-left: auto;
}

.iconOnly {
    flex-shrink: 0;
    justify-content: center;
    width: var(--nav-item-height);
    padding: 0;
    border-radius: 50%;
}

.iconOnly .iconLeading {
    margin-right: 0;
}

.iconOnly .iconTrailing {
    display: none;
}

.hasIconTrailing .text {
    margin-right: var(--space-4);
}

.iconOnly .text {
    display: none;
}

@media (max-width: 1199.98px) {
    .iconOnlyMobile {
        flex-shrink: 0;
        justify-content: center;
        width: var(--nav-item-height);
        padding: 0;
        border-radius: 50%;
    }

    .iconOnlyMobile.isDesktop.hasIconTrailing {
        padding-right: 0;
    }

    .iconOnlyMobile .iconLeading {
        margin-right: 0;
    }

    .iconOnlyMobile .text {
        display: none;
    }

    .iconOnlyMobile .iconTrailing {
        display: none;
    }

    .iconOnlyMobile.isDesktop.hasIconLeading {
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .textOnlyDesktop .iconLeading {
        display: none;
    }
}
