.navMenuButton {
    width: fit-content;
    padding: var(--space-8) var(--space-16);
    border-radius: 8px;
    color: var(--color-primary-contrast-neutral);
}

.navMenuButton:hover {
    background-color: var(--color-green-800-30);
}
