.container {
    padding: var(--space-16);
    border-radius: 8px;
    background-color: var(--color-green-100);
}

.widget {
    width: 100%;
    height: 1000px;
}
