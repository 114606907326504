.grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 var(--space-16);
    gap: var(--space-16);
}

.footer {
    padding: var(--space-56) var(--space-16) 0 var(--space-16);
    text-align: center;
}
