.sidebar {
    display: flex;
    flex: 1 1 0%;
    max-width: 600px;
    padding: var(--space-8);
}

.welcomeTitle {
    display: none;
    margin-bottom: var(--space-24);
    color: var(--color-text-main);
}

.welcomeName {
    color: var(--color-primary-main);
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: var(--space-8);
    color: var(--color-text-light);
    text-align: center;
}

.icon {
    width: var(--space-24);
    height: var(--space-24);
}

.item:hover {
    color: var(--color-primary-hovered);
    fill: var(--color-primary-hovered);
}

.item:active,
.item:focus {
    color: var(--color-primary-pressed);
    fill: var(--color-primary-pressed);
}

.selected {
    color: var(--color-primary-main);
    fill: var(--color-primary-main);
}

.mobileLabel {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
}

.desktopLabel {
    display: none;
}

@media (min-width: 1200px) {
    .sidebar {
        flex-direction: column;
        width: 320px;
        padding: var(--space-32) var(--space-8);
    }

    .item {
        flex-direction: row;
        padding: var(--space-16);
    }

    .welcomeTitle {
        display: block;
        padding: 0 var(--space-16);
    }

    .mobileLabel {
        display: none;
    }

    .desktopLabel {
        display: block;
    }
}
