.container {
    margin-bottom: var(--space-64);
}

.list {
    display: flex;
    flex-direction: column;
    margin-top: var(--space-16);
    gap: var(--space-32);
}
