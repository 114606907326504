.localHeroExplanation {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: var(--space-24);
    gap: var(--space-24);
    background-color: var(--color-background-main);
    color: var(--color-text-main);
}

.localHeroExplanationTitle {
    width: 100%;
    color: var(--color-primary-main);
    text-align: left;
    text-wrap: balance;
}

.localHeroExplanationsList {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
}

.localHeroExplanationItem {
    display: flex;
    flex-basis: calc(100% / 3);
    align-items: center;
    gap: var(--space-16);
}

.italic {
    font-style: italic;
}

@media (min-width: 992px) {
    .localHeroExplanation {
        padding: var(--space-24) var(--space-80) var(--space-32) var(--space-80);
        gap: var(--space-16);
    }

    .localHeroExplanationTitle {
        text-align: center;
    }

    .localHeroExplanationsList {
        flex-direction: row;
    }

    .localHeroExplanation h5 {
        margin-bottom: 0;
    }

    .localHeroExplanationItem {
        margin: 0;
    }
}
