.reviewRating {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    color: currentColor;
}

.icon {
    width: var(--space-24);
    height: var(--space-24);
    margin-left: calc(-1 * var(--space-4));
    color: var(--color-secondary-main);
}

.icons,
.iconsAndCount {
    display: flex;
    align-items: center;
}

.iconsAndCount {
    flex-wrap: wrap;
    gap: var(--space-8);
}

.alignLeft,
.alignLeft .iconsAndCount {
    justify-content: flex-start;
}

.alignCenter,
.alignCenter .iconsAndCount {
    justify-content: center;
}

.alignRight,
.alignRight .iconsAndCount {
    justify-content: flex-end;
}

.large {
    align-items: baseline;
}

.small .icon {
    width: var(--space-16);
    height: var(--space-16);
    margin-left: calc(-1 * var(--space-4) / 2);
}

.large .icon {
    width: var(--space-32);
    height: var(--space-32);
}
