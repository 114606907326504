.container {
    --section-margin-x: var(--space-12);

    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    width: 100%;
    max-width: 1320px;
    margin: 0 calc(var(--section-margin-x) * -1);
}

.section {
    flex: 1 1 auto;
    margin: 0 var(--section-margin-x);
}

.list {
    display: flex;
    flex-direction: column;
    padding: var(--space-8);
}

.title {
    padding: var(--space-16) 0;
    border-bottom: 1px solid var(--color-neutral-900);
}

.item {
    flex: 1 0 0;
    padding: var(--space-8) 0;
    color: var(--color-neutral-900);
}

.item:hover {
    text-decoration: underline;
}

.imageWrapper {
    display: none;
    height: 50px;
    margin-bottom: 16px;
    color: var(--color-green-700);
}

@media (min-width: 576px) {
    .container {
        flex-direction: row;
    }
}

@media (min-width: 768px) {
    .container {
        flex-direction: column;
    }

    .list {
        flex-direction: row;
        justify-content: space-between;
    }

    .item {
        padding: var(--space-16);
    }

    .imageWrapper {
        display: block;
    }
}

@media (min-width: 1200px) {
    .container {
        flex-direction: row;
    }
}
