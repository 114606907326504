.mobileSubNav {
    --mobile-head-height: var(--primary-nav-height);

    display: flex;
    align-items: center;
    height: var(--mobile-head-height);
    margin-bottom: var(--space-16);
    padding: 0 var(--space-8);
    background-color: var(--color-green-700);
}

.mobileSubNav.sticky {
    z-index: var(--z-index-appbar);
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
}

.titleWrapper {
    width: 100%;
    min-width: 0;
    padding: 0 var(--space-8);
    text-align: center;
}

.title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
