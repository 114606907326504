.dropdownContent {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border: 1px solid var(--color-neutral-0-10);
    border-top: 0;
    background-color: var(--color-green-800);
    box-shadow: 0 10px 10px 0 rgb(43 30 20 / 7%);
    color: var(--color-neutral-100);
}

@media (min-width: 1400px) {
    .dropdownContent {
        right: var(--space-40);
        left: var(--space-40);
    }
}
