.emptyMessage {
    margin-bottom: var(--space-72);
}

.titleCreation,
.chooseDestinationTitle {
    margin-bottom: var(--space-8);
}

.ctaWrapper {
    display: flex;
    flex-direction: column;
}

@media (min-width: 576px) {
    .ctaWrapper {
        flex-direction: row;
    }
}
