.section {
    --tile-grid-gutter: var(--space-16);
    --tile-grid-nb-per-row: 3;

    margin-bottom: var(--space-24);
}

.section:last-child {
    margin-bottom: 0;
}

.section::before,
.section::after {
    display: table;
    content: ' ';
}

.section::after {
    clear: both;
}

.sectionTitle {
    margin-bottom: var(--space-24);
}

.topDestinationsList {
    margin-top: calc(-1 * var(--tile-grid-gutter));
    margin-left: calc(-1 * var(--tile-grid-gutter));
    padding: 0;
    list-style: none;
}

.topDestinationsListItem {
    display: block;
    width: calc((100% / var(--tile-grid-nb-per-row)) - var(--tile-grid-gutter));
    margin-top: calc(1 * var(--tile-grid-gutter));
    margin-left: calc(1 * var(--tile-grid-gutter));
    float: left;
}

.findDestination {
    display: block;
    position: relative;
    padding-top: 100%;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 0 0 2px var(--color-neutral-0);
    transition: background-color 0.3s ease-out;
}

.findDestination:hover {
    background-color: rgb(255 255 255 / 20%);
}

.findDestinationText {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 var(--space-8) var(--space-8) var(--space-8);
    hyphens: auto;
}

.otherDestinationsList {
    columns: 2;
    list-style: none;
}

.tab {
    min-height: var(--space-40);
}

.tabListWrapper {
    display: flex;
    align-items: center;
    min-height: var(--space-56);
    padding: 0 var(--space-16);
    background-color: var(--color-neutral-0-10);
}

.tabContent {
    padding: var(--space-24) var(--space-16);
}

@media (min-width: 768px) {
    .section {
        --tile-grid-nb-per-row: 5;
    }

    .topDestinationsListItem {
        width: var(--space-104);
    }

    .otherDestinationsList {
        columns: 5;
    }
}

@media (min-width: 992px) {
    .section {
        --tile-grid-nb-per-row: 7;
    }
}

@media (min-width: 1200px) {
    .section {
        --tile-grid-nb-per-row: 9;
    }

    .tabContent {
        padding: var(--space-40) 0 0 0;
    }
}
