.container {
    min-height: calc(100% - var(--primary-nav-height));
    color: var(--color-neutral-100);
}

@media (min-width: 1200px) {
    .container {
        display: none;
    }
}
