.container {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--space-56);
}

.title {
    margin-bottom: var(--space-24);
    color: var(--color-text-main);
}

.carousel {
    margin-bottom: var(--space-24);
}

.localHeroExplanation {
    background-color: var(--color-background-variant);
}
