.tabList {
    display: flex;
    max-width: 100%;
    margin: 0;
    overflow: auto hidden;
    gap: var(--space-8);
    list-style: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
}

.tabList::-webkit-scrollbar {
    display: none;
}

.tabListVertical {
    flex-direction: column;
}

.tab {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: var(--space-8) var(--space-16);
    gap: var(--space-4);
    border: none;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    color: var(--color-neutral-800);
    cursor: pointer;
}

.tab:hover,
.tab[aria-selected='true'] {
    border-color: var(--color-green-600);
    color: var(--color-green-700);
}

.thematicTab {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    width: 112px;
    padding: var(--space-8);
    gap: var(--space-8);
    border: none;
    background-color: transparent;
    color: var(--color-neutral-800);
    cursor: pointer;
}

.thematicTab .tabIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: var(--color-beige-100);
    color: var(--color-neutral-900);
}

.thematicTab .tabIcon {
    width: 32px;
    height: 32px;
    transition: transform 0.1s ease-in-out;
}

.thematicTab[aria-selected='true'] .tabIconWrapper {
    background-color: var(--color-green-900);
    color: var(--color-green-100);
}

.thematicTab:not([aria-selected='true']):hover .tabIcon {
    transform: scale(1.1);
}

.tabListReversed .tab {
    color: var(--color-neutral-100);
}

.tabListReversed .tab:hover,
.tabListReversed .tab[aria-selected='true'] {
    border-color: var(--color-yellow-100);
    color: var(--color-yellow-300);
}

.navMenuVerticalTab {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
    padding: 0 var(--space-24);
    gap: var(--space-8);
    border: 0;
    border-radius: 8px;
    background-color: transparent;
    color: var(--color-primary-contrast-neutral);
    cursor: pointer;
}

.navMenuVerticalTab:hover {
    background-color: var(--color-green-700-40);
}

.navMenuVerticalTab[aria-selected='true'] {
    background-color: var(--color-primary-main);
}

.navMenuVerticalTabIcon {
    width: 24px;
    height: 24px;
}

@media (min-width: 576px) {
    .thematicTab {
        width: 128px;
    }
}
