.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--space-16);
    padding: 0 var(--grid-gutter-x);
}

.footer {
    padding: var(--space-56) var(--space-16) 0 var(--space-16);
    text-align: center;
}
